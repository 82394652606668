// @flow

import createClient from '../api/client';

export default {
  getFeedbacksByCompanyId: (companyId: number) =>
    createClient()
      .get('/net-auctions/feedbacks', { params: { companyId } })
      .then(response => response.data),

  getFeedbackByUuid: (uuid: string) =>
    createClient()
      .get(`/net-auctions/feedbacks/${uuid}`)
      .then(response => response.data),

  saveFeedback: (uuid: string, params: Object) =>
    createClient()
      .patch(`/net-auctions/feedbacks/${uuid}`, params)
      .then(response => response.data),

  fetchUserFeedbacks: (userId: number) =>
    createClient()
      .get(`users/${userId}/feedbacks`)
      .then(response => response.data),
};

export const companyTypesWithoutFeedback = ['Ulosotto', 'Kuluttaja'];
